import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import ApproveOrRejectVideoModal from "../Common/ApproveOrRejectVideoModal";
import CustomModal from "../Common/CustomModal";
import ModeratorStatusChip from "../Common/ModeratorStatusChip";

const VideoChecks = ({ series, setShowEdit, setMessage, setReload }) => {
  const [viewThumbnailAndIcon, setViewThumbnailAndIcon] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    approve: false,
    reject: false,
  });
  const disableApprove = series?.review_status === "approved";
  const disableReject =
    series?.review_status === "rejected" || series?.status === "live";

  return (
    <div>
      <div className="mt-4 border-t">
        <div className="flex justify-between items-center mt-2">
          <div className="flex gap-x-4 items-center">
            <p className="text-[15px] font-semibold">
              Video Checks{" "}
              {series?.image && series?.title_icon ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-red-500"
                />
              )}
            </p>
            <ModeratorStatusChip
              className="py-[3px]"
              reviewStatus={series?.review_status}
            />
          </div>

          <div className="flex gap-x-2">
            <button
              className={` bg-baseBlueColor text-white px-3 py-2 text-[14px] rounded-md ${
                disableApprove ? "opacity-50" : "opacity-100"
              } `}
              onClick={() =>
                setConfirmationModal({ approve: true, reject: false })
              }
              disabled={disableApprove}
            >
              Approve
            </button>
            <button
              className={` bg-red-600 text-white px-3 py-2 text-[14px] rounded-md ${
                disableReject ? "opacity-50" : "opacity-100"
              }`}
              onClick={() =>
                setConfirmationModal({ approve: false, reject: true })
              }
            >
              Reject
            </button>
          </div>
        </div>
        <div className="flex gap-x-4 mt-3 items-start">
          {series?.image ? (
            <div className="flex gap-x-2 items-center">
              <button
                onClick={() => setViewThumbnailAndIcon(true)}
                className="text-[14px] flex gap-x-3 items-center justify-center underline cursor-pointer text-green-500"
              >
                View Thumbnail{" "}
              </button>
              <FontAwesomeIcon
                icon={faPen}
                className="text-[11px] text-gray-400 cursor-pointer"
                onClick={() => setShowEdit(true)}
              />
            </div>
          ) : (
            <button
              className="text-[12px] underline"
              onClick={() => setShowEdit(true)}
            >
              Add Thumbnail
            </button>
          )}

          {series?.title_icon ? (
            <div className="flex gap-x-3 items-center">
              <button
                onClick={() => setViewThumbnailAndIcon(true)}
                className="text-[14px] underline cursor-pointer text-green-500"
              >
                View Title Icon{" "}
              </button>
              <FontAwesomeIcon
                icon={faPen}
                className="text-[11px] text-gray-400 cursor-pointer"
                onClick={() => setShowEdit(true)}
              />
            </div>
          ) : (
            <button
              className="text-[12px] underline"
              onClick={() => setShowEdit(true)}
            >
              Add Title Icon
            </button>
          )}
        </div>
      </div>

      <ApproveOrRejectVideoModal
        seriesSlug={series?.slug}
        openIsApprove={confirmationModal?.approve}
        openIsRejected={confirmationModal?.reject}
        setMessage={setMessage}
        setReload={setReload}
        handleClose={() =>
          setConfirmationModal({ approve: false, reject: false })
        }
      />

      {viewThumbnailAndIcon && (
        <CustomModal
          show={viewThumbnailAndIcon}
          title={series?.display_title}
          isButtonsRequired={false}
          onHide={() => setViewThumbnailAndIcon(false)}
        >
          <div className="flex justify-between items-start w-[85%] mx-auto">
            <div>
              <h6 className="text-[14px] text-center">Thumbnail</h6>
              <img
                src={series?.image}
                alt={series?.display_title}
                className="w-[140px] h-[190px] rounded-md mt-2"
              />
            </div>

            <div>
              <h6 className="text-[14px] text-center">Title Icon</h6>
              <img
                src={series?.title_icon}
                alt={series?.title_icon}
                className="w-[150px] bg-black rounded-sm mt-2"
              />
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default VideoChecks;
