const seriesStatus = {
  LIVE: "live",
  DRAFT: "draft",
  UNDER_REVIEW: "under_review",
  CHANGES_REQUIRED: "changes_required",
  SCHEDULED: "scheduled",
  DELETED: "deleted",
  SUCCESSFUL: "Successful",
  FAILURE: "Failure",
  TRACKING: "Tracking",
  FAILED: "Failed",
};

const handleSeriesStatusColor = ({ status }) => {
  switch (true) {
    case status === seriesStatus.LIVE || status === seriesStatus.SUCCESSFUL:
      return `!text-green-500 !bg-green-100`;
    case status === seriesStatus.DRAFT || status === seriesStatus.TRACKING:
      return `!text-blue-500 !bg-blue-100`;
    case status === seriesStatus.UNDER_REVIEW:
      return `!text-yellow-700 !bg-yellow-300`;
    case status === seriesStatus.DELETED ||
      status === seriesStatus.FAILURE ||
      status === seriesStatus.FAILED:
      return `!text-red-500 !bg-red-100`;
    case status === seriesStatus.SCHEDULED:
      return `!text-orange-500 !bg-orange-100`;
    case status === seriesStatus.CHANGES_REQUIRED:
      return `!text-purple-500 !bg-purple-100`;
    default:
      return "!text-white !bg-gray-400";
  }
};

const handleSeriesStateTabs = ({ hasAccessToAllTabs }) => {
  const adminTabStates = [
    {
      id: "ALL",
      key: "SERIES",
      index: 0,
      value: "all",
      show: true,
    },
    {
      id: "DRAFT",
      key: "DRAFT",
      index: 1,
      value: "draft",
      show: true,
    },
    {
      id: "UNDER REVIEW",
      key: "UNDER_REVIEW",
      index: 2,
      value: "under_review",
      show: true,
    },
    {
      id: "LIVE",
      key: "LIVE",
      index: 3,
      value: "live",
      show: true,
    },
    {
      id: "CHANGES REQUIRED",
      key: "CHANGES_REQUIRED",
      index: 4,
      show: true,
      value: "changes_required",
    },
    {
      id: "SCHEDULED",
      key: "SCHEDULED",
      index: 5,
      show: true,
      value: "scheduled",
    },
    {
      id: "DELETED",
      key: "DELETED",
      index: 6,
      show: true,
      value: "deleted",
    },
    {
      id: "EXPIRED",
      key: "EXPIRED",
      index: 7,
      show: true,
      value: "expired",
    },
  ];

  const creatorTabStates = [
    {
      id: "ALL",
      key: "SERIES",
      index: 0,
      value: "all",
      show: true,
    },
    {
      id: "DRAFT",
      key: "DRAFT",
      index: 1,
      value: "draft",
      show: true,
    },
    {
      id: "LIVE",
      key: "LIVE",
      index: 2,
      value: "live",
      show: true,
    },
    {
      id: "DELETED",
      key: "DELETED",
      index: 3,
      show: true,
      value: "deleted",
    },
  ];
  return hasAccessToAllTabs ? adminTabStates : creatorTabStates;
};

const handleTableHeading = ({
  isScheduledTab,
  isExpiredTab,
  hasAccessToActions,
  isCreator,
  categoryAndCreatorTab,
}) => {
  const heading = [
    {
      id: 1,
      display: true,
      title: "Thumbnail",
    },
    {
      id: 2,
      display: true,
      title: "Title",
    },
    {
      id: 3,
      display: true,
      title: isScheduledTab ? "Scheduled On" : "Uploaded On",
    },
    {
      id: 3.1,
      display: !isScheduledTab,
      title: "Published On",
    },
    {
      id: 4,
      display: true,
      title: "Completion Rate",
    },
    {
      id: 5,
      display: isCreator,
      title: "Payout",
    },
    {
      id: 6,
      display: isCreator,
      title: "Duration",
    },
    {
      id: 8,
      display: categoryAndCreatorTab, // hiding category for creators
      title: "Category",
    },
    {
      id: 9,
      display: categoryAndCreatorTab, // hiding for creators
      title: "Creator",
    },
    {
      id: 7,
      display: true,
      title: "Status",
    },
    {
      id: 10,
      display: !isExpiredTab && hasAccessToActions, // hiding for creators
      title: "Action",
    },
  ];

  return heading;
};

const allSeriesDefaultState = {
  nPages: 0,
  series: [],
  shows: [],
};

const openScheduledModalState = {
  slug: "",
  openModal: false,
};

const moderationStatus = [
  {
    id: 1,
    title: "Approved",
    value: "approved",
  },
  { id: 2, title: "Rejected", value: "rejected" },
];

export {
  seriesStatus,
  allSeriesDefaultState,
  openScheduledModalState,
  moderationStatus,
  handleSeriesStatusColor,
  handleSeriesStateTabs,
  handleTableHeading,
};
