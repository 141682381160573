import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Modal, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import AddSeriesVideos from "../Add-Series-Videos/add-series-videos.component";
import {
  getDateString,
  schedularTimeList,
  handleFormatDate,
  handleDownloadThumbnail,
} from "../../utils/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import "./series-details.styles.css";
import VideoDetail from "../Video-Detail/video-detail.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import {
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faPlayCircle,
  faUser,
  faEllipsisH,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { selectConfigFilters } from "../../redux/config/config.selector";
import Divider from "@mui/material/Divider";
import EditSeries from "../Edit-Series/edit-series.component";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "../Loader";
import {
  removeSeries,
  updateSeries,
  fetchRejectionReason,
  changeSeriesStatusStart,
  changeSeriesStatusFailure,
} from "../../redux/series/series.action";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import UpdateVideo from "../Update-Video/update-video.component";
import AlertComponent from "../Alert-Messages/alert-component.component";
import SeriesExperiments from "../Series-Experiments/series-experiments.component";
import AddExperiment from "../Add-Experiment/add-experiment.component";
import { fetchSeriesNotificationSuccess } from "../../redux/seriesDetails/seriesDetails.action";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PromptModal from "./Prompt";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { apiGateway } from "../../utils/config";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import moment from "moment";
import VideoPlayerCustom from "../VideoPlayer/VideoPlayerCustom";
import {
  GET_ADMIN_SERIES,
  GET_PRESIGNED_URL,
  GET_SERIES_V1,
  NOTIFICATION_V1,
  SERIES_SCHEDULE_V1,
  SERIES_V1_1,
  SHARE_LINK_V1,
} from "../../utils/constants";
import useZustandStore from "../../zustandStore/useZustandStore";
import VideoPlayOnSeriesClick from "../Common/VideoPlayOnSeriesClick";
import CustomModal from "../Common/CustomModal";
import useUserRoles from "../../hooks/useUserRoles";
import VideoChecks from "./VideoChecks";
import VideoStatusChip from "../Common/VideoStatusChip";
import { videoDetailConfirmationDefaultState } from "../../utils/constants/VideoDetails";

function SeriesDetails({ history, match }) {
  const videoRef = useRef();
  const videoRefTrailer = useRef();
  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const anchorRef = React.useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const seriesSlug = match.params?.series_slug;
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [trailerData, setTrailerData] = useState({});
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [seriesVideosOriginal, setSeriesVideosOriginal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [showAddVideos, setShowAddVideos] = useState(false);
  const [showExperiments, setShowExperiments] = useState(false);
  const [showAddExperiments, setShowAddExperiments] = useState(false);
  const [series, setSeries] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(1);
  const [showVideo, setShowVideo] = useState(false);
  const [videoSource, setVideoSource] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [playIndex, setPlayIndex] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [isContentChanged, setIsContentChanged] = useState(false);
  const [isVideosFetching] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);
  const [primaryTags, setPrimaryTags] = useState([]);
  const [secondaryTags, setSecondaryTags] = useState([]);
  const [reload, setReload] = useState(true);
  const [autoplay, setAutoplay] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isQuizShown, setIsQuizShown] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [messageType, setMessageType] = useState("failed");
  const [messageResponse, setMessageResponse] = useState("");
  const [deleteID, setDeleteID] = useState(null);
  const [promptAction, setPromptAction] = useState(false);
  const [rejectedSeriesSlug, setRejectedSeriesSlug] = useState("");
  const [seriesCurrentStatus, setSeriesCurrentStatus] = useState(null);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [errorForScheduling, setErrorForScheduling] = useState([]);
  const [scheduledData, setScheduledData] = useState({});
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const isUpdating = useSelector((state) => state.series?.isUpdating);
  const [os, setOs] = useState(0);
  const [playTrailer, setPlayTrailer] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [showTrailerModal, setShowTrailerModal] = useState(false);
  const [showRejectionReasonModal, setShowRejectionReasonModal] =
    useState(false);
  const [
    showRejectionReasonsListForSeriesModal,
    setShowRejectionReasonsListForSeriesModal,
  ] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(
    videoDetailConfirmationDefaultState
  );
  const errorMessageOnLatestAction = useSelector(
    (state) => state.series.errorMessage
  );
  const [rejectionReasonField, setRejectionReasonField] = useState("");
  const { isAdmin, isSuperAdmin, isCreator, internalTeamAccess, isModerator } =
    useUserRoles();
  const { showAddVideosModal, setShowAddVideosModal, setToastMessage } =
    useZustandStore();
  const hasAccessToAddVideo = internalTeamAccess || isCreator;
  const hasAccessToEdit = internalTeamAccess || isCreator || isModerator;
  const hasAccessToModerate = isSuperAdmin || isModerator || isAdmin;
  const languageMapping = useSelector(selectConfigFilters)?.language_mapping;
  const openConfirmationIf =
    openConfirmation.publishNow ||
    openConfirmation.unPublish ||
    openConfirmation.delete;

  const quickFiltersSeries = Object.freeze({
    DRAFT: "DRAFT",
    UNDER_REVIEW: "UNDER_REVIEW",
    LIVE: "LIVE",
    CHANGES_REQUIRED: "changes_required",
    SCHEDULED: "scheduled",
  });

  const reasonData = useSelector((state) => {
    return state.series.rejectionReasonData;
  });

  const seriesDataMapToEnum = (seriesStatus) => {
    if (seriesStatus === "live") {
      setSeriesCurrentStatus(quickFiltersSeries.LIVE);
    } else if (seriesStatus === "draft") {
      setSeriesCurrentStatus(quickFiltersSeries.DRAFT);
    } else if (seriesStatus === "under_review") {
      setSeriesCurrentStatus(quickFiltersSeries.UNDER_REVIEW);
    } else if (seriesStatus === "changes_required") {
      setSeriesCurrentStatus(quickFiltersSeries.CHANGES_REQUIRED);
    } else if (seriesStatus === "scheduled") {
      setSeriesCurrentStatus(quickFiltersSeries.SCHEDULED);
    }
  };

  useEffect(() => {
    async function change() {
      if (!isContentChanged || reload) {
        const url = `${apiGateway}${SERIES_V1_1}${seriesSlug}/`;
        axiosPrivate
          .get(url)
          .then(({ data, status }) => {
            if (data && status === 200) {
              setSeries(data.series);
              seriesDataMapToEnum(data.series.status);
              dispatch(updateSeries(data.series));
              setIsContentChanged(false);
              setNotificationData(data.series?.series_notification);
              setScheduledData(data.series?.scheduled_info);
              setTrailerData(data?.series?.trailer_info);
              setReload(false);
              dispatch(
                fetchSeriesNotificationSuccess(data.series?.series_notification)
              );
            }
          })
          .catch(({ response }) => {
            setErrorMsg({
              error: response?.data
                ? response?.data?.error_message
                : "Unable to fetch this moment",
              type: "failed",
            });
          });
      }
    }
    if (reload) change();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentChanged, reload]);

  useEffect(() => {
    if (!isLoading) {
      fetchMore(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, reload]);

  const fetchMore = async (page) => {
    if (page === -1 && !isLoading) {
      setPageNumber(lastPageNumber);
    } else if (page === 0 && !isLoading) {
      setPageNumber(1);
    } else if (!isLoading) {
      setIsLoading(true);
      const url = `${apiGateway}${SERIES_V1_1}${seriesSlug}/units/?page=${pageNumber}&lang=en`;

      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (data && data.page === pageNumber && status === 200) {
            setIsLoading(false);
            if (pageNumber === 1) {
              setPrimaryTags(data?.series?.primary_tags);
              setSecondaryTags(data.series.secondary_tags);
            }

            setHasMoreVideos(data.has_more);
            if (data.content_units) {
              if (pageNumber > 1) {
                setSeriesVideos((prev) => [...prev, ...data.content_units]);
                setSeriesVideosOriginal((prev) => [
                  ...prev,
                  ...data.content_units,
                ]);
              } else {
                setSeriesVideos([...data.content_units]);
                setSeriesVideosOriginal([...data.content_units]);
              }
              //temp solution to load all the video for next play
              if (data.has_more) {
                setIsLoading(false);
              }
            } else {
              history.push("/videos");
            }
            if (errorFetching) {
              setErrorMsg({
                error: "Unable to fetch videos this moment",
                type: "failed",
              });
              setErrorFetching(false);
            }
            setReload(false);
          }
        })
        .catch(({ response }) => {
          setErrorMsg({
            error: response?.data
              ? response?.data?.error_message
              : "Unable to fetch videos this moment",
            type: "failed",
          });
          setErrorFetching(true);
        });
    }
  };

  useEffect(() => {
    if (showUpdate) {
      setPageNumber(1);
    }
  }, [showUpdate]);

  useEffect(() => {
    const detectedOs = detectOS();
    setOs(detectedOs);
  }, []);

  const detectOS = () => {
    const platform = navigator.platform;
    if (platform?.indexOf("Win") !== -1) return 1;
    if (platform?.indexOf("Mac") !== -1) return 0;
    if (platform?.indexOf("Linux") !== -1) return 1;
    if (platform?.indexOf("iPhone") !== -1) return 0;
    if (platform?.indexOf("Android") !== -1) return 1;
    if (platform?.indexOf("iPad") !== -1) return 0;
    return "Unknown";
  };

  useEffect(() => {
    if (!isUpdating) setReload(true);
  }, [isUpdating]);

  const deleteSeries = async () => {
    try {
      setOpenConfirmation({ ...openConfirmation, isLoading: true });
      const url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/`;
      const { data } = await axiosPrivate.delete(url);
      if (data) {
        setOpenConfirmation(videoDetailConfirmationDefaultState);
        setErrorMsg({
          type: "success",
          error: `Series ${data.series.status}`,
        });
        dispatch(removeSeries(series.id));
        history.push("/videos");
      }
    } catch (error) {
      setOpenConfirmation({ ...openConfirmation, isLoading: false });
      setErrorMsg({
        error:
          error.response?.data?.message ||
          error.response?.data?.error_message ||
          "An error occurred",
        type: "failed",
      });
    }
  };

  const toggleRejectionSeriesList = () => {
    setShowRejectionReasonsListForSeriesModal((prev) => !prev);
  };

  const toggleRejection = () => {
    setShowRejectionReasonModal((prev) => !prev);
  };

  const onRejectionFieldChange = (e) => {
    setRejectionReasonField(e.target.value);
  };

  const changeSeriesStatusAsync = async (seriesSlug, action, reasonEntered) => {
    dispatch(changeSeriesStatusStart());
    setOpenConfirmation({ ...openConfirmation, isLoading: true });
    let urlPublish = `${apiGateway}${SERIES_V1_1}${seriesSlug}/publish/`;
    let urlReview = `${apiGateway}${GET_ADMIN_SERIES}${seriesSlug}/review/`;
    let finalUrl = "";
    let body = {
      action: action === "publish_now" ? "approved" : action,
    };
    if (action === "publish" || action === "unpublish") {
      finalUrl = urlPublish;
    } else if (action === "publish_now") {
      finalUrl = urlReview;
      body["publish_now"] = true;
    } else if (action === "changes_required") {
      finalUrl = urlReview;
      body = {
        action,
        reason: reasonEntered,
      };
    } else {
      finalUrl = urlReview;
    }
    axiosPrivate
      .post(finalUrl, body)
      .then(({ data, status }) => {
        if (status !== 200) {
          setErrorMsg({
            type: "failed",
            error: !data?.error_code
              ? "Unable to update status."
              : data?.error_message,
          });
        } else {
          setOpenConfirmation(videoDetailConfirmationDefaultState);
          setErrorMsg({
            type: "success",
            error: "Series Status Changed",
          });
          dispatch(updateSeries(data.series));
          setReload(true);
        }
      })
      .catch(({ response }) => {
        setOpenConfirmation({ ...openConfirmation, isLoading: false });
        setErrorMsg({
          error: response?.data
            ? response?.data?.error_message || response?.data?.message
            : "Oops! Unable to update status",
          type: "failed",
        });
      });
  };

  const rejectReasonSubmit = async () => {
    if (rejectionReasonField != "") {
      changeSeriesStatusAsync(
        rejectedSeriesSlug,
        "changes_required",
        rejectionReasonField
      );
      setShowRejectionReasonModal(false);
    } else {
      setErrorMsg({
        type: "failed",
        error: "Please fill the reason !",
      });
    }
  };
  // Get Rejection data for the series
  const renderRejectedReasonData = () => {
    if (reasonData != undefined) {
      if (reasonData.reasons.length > 0) {
        const finalReasons = reasonData?.reasons?.map((d, i) => {
          return (
            <>
              <ListItem>
                <ListItemText
                  primary={d.reason}
                  secondary={handleFormatDate({ dateInString: d.rejected_on })}
                ></ListItemText>
              </ListItem>

              <Divider component="li" />
            </>
          );
        });
        const listToReturn = <List>{finalReasons}</List>;
        return listToReturn;
      } else {
        return <li>No logs for rejection reasons</li>;
      }
    }
    return <li>No logs for rejection reasons</li>;
  };

  const handleVideoModal = (videoSource, videoTitle, playIndex) => {
    setIsQuizShown(false);
    setAutoplay(false);
    if (showVideo) {
      setShowVideo(false);
      setPlayIndex(0);
      setVideoSource(false);
      return;
    } else {
      setVideoSource(videoSource);
      setVideoTitle(videoTitle);
      setShowVideo((prev) => !prev);
      setPlayIndex(playIndex);
      playerRef.current = null;
    }
  };

  //plays video and point to next video
  const playVideoNext = (currentPlayIndex) => {
    setIsQuizShown(false);
    const index = currentPlayIndex >= 0 ? currentPlayIndex : playIndex + 1;

    if (index >= 0 && index < seriesVideos.length) {
      const video = seriesVideos[index];
      setSelectedVideo(video);
      const source =
        video.status.toLowerCase() === "live"
          ? video.content.h264_media_url
            ? video.content.h264_media_url
            : video.content.url
          : null;
      setVideoSource(source);
      setVideoTitle(video?.title);
      setPlayIndex(index);
      setShowVideo(true);
    } else if (hasMoreVideos) {
      setPageNumber(pageNumber + 1);
    }
  };

  const playVideoPrev = () => {
    setIsQuizShown(false);
    const index = playIndex - 1;
    if (index >= 0 && index < seriesVideos.length) {
      const video = seriesVideos[index];
      setSelectedVideo(video);
      setVideoSource(
        video.status.toLowerCase() === "live"
          ? video.content.h264_media_url
            ? video.content.h264_media_url
            : video.content.url
          : null
      );
      setVideoTitle(video?.title);
      setPlayIndex(index);
      setShowVideo(true);
    }
  };

  const onVideoEnd = () => {
    if (autoplay) {
      setIsQuizShown(false);
      playVideoNext();
    }
  };

  const playAllVideos = () => {
    const video = seriesVideos[0];
    if (video) setSelectedVideo(video);
    setIsQuizShown(false);
    setAutoplay(true);
    setPlayIndex(0);
    playVideoNext(0);
  };

  const playTrailerVideos = () => {
    setPlayTrailer(true);
    setAutoplay(true);
  };

  const handleDrag = (params) => {
    if (!isContentChanged) setIsContentChanged(true);
    const srcI = params?.source?.index;
    const destI = params?.destination?.index;
    setSeriesVideos((prev) => {
      prev.splice(destI, 0, prev.splice(srcI, 1)[0]);
      return prev;
    });
    setSeriesVideosOriginal((prev) => {
      prev.splice(destI, 0, prev.splice(srcI, 1)[0]);
      return prev;
    });
  };

  const addSeriesVideo = (video) => {
    if (!isContentChanged) setIsContentChanged(true);
    setSeriesVideos((prev) => [...prev, video]);
    setSeriesVideosOriginal((prev) => [...prev, video]);
  };

  const cancelChanges = () => {
    const isCancel = window.confirm("Do you want to cancel changes ?");
    if (isCancel) {
      setIsContentChanged(false);
      setSeriesVideos([...seriesVideosOriginal]);
    }
  };

  const removeVideo = (video) => {
    if (!isContentChanged) setIsContentChanged(true);
    const videos = seriesVideos.filter(
      (seriesVideo) => seriesVideo.id !== video.id
    );
    setSeriesVideos([...videos]);
  };

  const updateVideo = (video) => {
    setSelectedVideo(video);
    setShowUpdate(true);
  };

  const updateSeriesVideos = async () => {
    const url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/update-content/`;
    const videoIds = [];
    seriesVideos.forEach((video) => {
      videoIds.push(video.id);
    });
    const data = {
      content_unit_ids: videoIds,
    };
    axiosPrivate
      .post(url, data)
      .then(({ data, status }) => {
        if (status === 200) {
          setErrorMsg({
            type: "success",
            error: "Saved !",
          });
          setIsContentChanged(false);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Something went wrong",
        });
      });
  };

  const observer = useRef();
  const lastVideoElementRef = useCallback(
    (node) => {
      if (isVideosFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          if (errorFetching) {
            setLastPageNumber(pageNumber);
            setPageNumber(-1);
          } else {
            setPageNumber((prevState) => prevState + 1); //delete the pageNumber from Redux state
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isVideosFetching, hasMoreVideos]
  );

  const onTimeUpdate = () => {
    if (
      videoRef?.current &&
      !isQuizShown &&
      videoRef.current.currentTime >= selectedVideo?.quiz?.time_ms / 1000
    ) {
      // videoRef.current.pause();
    }
  };

  const getDateTimeFormat = (date) => {
    const newDate = moment(date).format("DD MM YYYY");
    return newDate?.split(" ").join(" - ");
  };

  const handleDelete = async () => {
    let url = `${apiGateway}${NOTIFICATION_V1}${deleteID}/delete/`;
    axiosPrivate
      .delete(url)
      .then(({ data, status }) => {
        if (
          (status === 200 && data?.status == "success") ||
          data?.message?.length > 0
        ) {
          setMessageResponse("Notification deleted successfully");
          setMessageType("success");
          dispatch(
            fetchSeriesNotificationSuccess({
              status: false,
              data: {},
            })
          );
          setNotificationData({ status: false });
        } else {
          setMessageResponse(
            data?.error_message ?? "Unable to delete notification"
          );
          setMessageType("failed");
        }
        setPromptAction(false);
        setDeleteID(null);
      })
      .catch((err) => {
        setPromptAction(false);
        setDeleteID(null);
        setMessageResponse("Unable to delete notification");
        setMessageType("failed");
      });
  };

  const deleteNotification = async (data) => {
    let id = data?.data?.id;
    setPromptAction(true);
    setDeleteID(id);
  };

  const setUpScheduleDate = async (data, type, edit) => {
    let url = `${apiGateway}${SERIES_SCHEDULE_V1}`;
    let dynamicFormDate;

    if (type === "custom") {
      dynamicFormDate = new Date(data).toLocaleString();
    } else {
      dynamicFormDate = new Date(
        `${data?.value?.split("/")[0]}-${data?.value?.split("/")[1]}-${
          data?.value?.split("/")[2]
        }`
      );
    }

    const dynamicDate =
      type === "custom"
        ? `${dynamicFormDate?.split(",")[0]?.split("/")[2]}-${
            dynamicFormDate?.split(",")[0]?.split("/")[1]
          }-${dynamicFormDate?.split(",")[0]?.split("/")[0]}T${
            dynamicFormDate?.split(", ")[1]
          }+05:30`
        : `${dynamicFormDate.getFullYear()}-${
            dynamicFormDate.getMonth() + 1
          }-${dynamicFormDate.getDate()}T18:00:00+05:30`;

    let formData = new FormData();

    if (edit?.length > 0) {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slug", seriesSlug);
    } else {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slugs", JSON.stringify([seriesSlug]));
    }
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        const isScheduleSuccess =
          (status === 200 &&
            data?.failure_slugs?.length === 0 &&
            data?.success_count === 1) ||
          data?.series_schedule?.status;
        if (isScheduleSuccess) {
          setErrorMsg({ error: "Scheduled successfully", type: "success" });
          setPageNumber(1);
          setOpenScheduleModal(false);
          setReload(true);
        } else {
          if (data?.failure_slugs && data?.failure_slugs?.length > 0) {
            setErrorForScheduling(data?.failure_slugs);
          }
          if (data?.message?.length > 0) {
            setErrorMsg({ error: data?.message, type: "failed" });
          }
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response.data
            ? response?.data.message
            : "Unable to create scheduling",
          type: "failed",
        });
      });
  };

  const deleteSchedule = async (data) => {
    let url = `${apiGateway}${GET_SERIES_V1}${data}/schedule/delete/`;
    axiosPrivate
      .delete(url)
      .then(({ data, status }) => {
        if (data?.error_code?.length > 0 || status !== 200) {
          if (data?.message?.length > 0) {
            setErrorMsg({ error: data?.message, type: "failed" });
          }
          setErrorMsg({ error: data?.error_message, type: "failed" });
        } else {
          setErrorMsg({ error: data?.message, type: "success" });
          setPageNumber(1);
          setReload(true);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response?.data
            ? response?.data?.message
            : "Unable to remove scheduling",
          type: "failed",
        });
      });
  };

  const copyShortLink = (slug) => {
    setIsLinkLoading(true);
    let url = `${apiGateway}${SHARE_LINK_V1}?series=${slug}`;
    axiosPrivate
      .get(url)
      .then(async ({ data, status }) => {
        if (data?.error_code?.length > 0 || status !== 200) {
          if (data?.message?.length > 0) {
            setErrorMsg({
              error: "Unable to generate short link",
              type: "failed",
            });
          }
          setErrorMsg({
            error: "Unable to generate short link",
            type: "failed",
          });
        } else {
          var copyText = data?.short_link;
          await navigator?.clipboard?.writeText(copyText);
          setErrorMsg({ error: "Short link copied", type: "success" });
        }
        setIsLinkLoading(false);
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response?.data
            ? response?.data?.error_message
            : "Unable to generate short link",
          type: "failed",
        });
        setIsLinkLoading(false);
      });
  };

  const handleTrailerSubmit = (e) => {
    e.preventDefault();
    if (trailerData?.file) {
      setIsLoading(true);
      const data = new FormData();
      data.append("media_extension", ".mp4");
      data.append("series_slug", seriesSlug);
      data.append("duration_s", videoDuration);

      try {
        let url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}${GET_PRESIGNED_URL}`;
        axiosPrivate
          .post(url, data)
          .then(async ({ data, status }) => {
            const videoData = new FormData();
            if (status === 200) {
              videoData.append("url", data?.upload_presigned_url.fields.key);
              videoData.append("video", trailerData?.file);
              fetch("/upload", {
                method: "POST",
                body: videoData,
              })
                .then((res) => {
                  const formData = new FormData();
                  formData.append("trailer_doc", JSON.stringify(data?.trailer));
                  let url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/trailer/create/`;
                  axiosPrivate
                    .post(url, formData)
                    .then(({ data, status }) => {
                      if (status == 200) {
                        setShowTrailerModal((prev) => false);
                        setErrorMsg({
                          error: "Trailer uploaded successfully",
                          type: "success",
                        });
                      } else {
                        setErrorMsg({
                          error: "Unable to upload trailer",
                          type: "failed",
                        });
                      }
                      setIsLoading(false);
                      setReload((prev) => !prev);
                    })
                    .catch((error) => {
                      setIsLoading(false);
                      setShowTrailerModal((prev) => false);
                      setErrorMsg({
                        error: "Unable to upload trailer",
                        type: "failed",
                      });
                    });
                })
                .catch(({ response }) => {
                  setIsLoading(false);
                  setShowTrailerModal((prev) => false);
                  setErrorMsg({
                    error: response?.data
                      ? response?.data?.error_message
                      : "Something went wrong",
                    type: "failed",
                  });
                });
            }
          })
          .catch(({ response }) => {
            setErrorMsg({
              error: response?.data
                ? response?.data?.error_message
                : "Something went wrong",
              type: "failed",
            });
            setIsLoading(false);
            setShowTrailerModal((prev) => false);
          });
      } catch (err) {
        setErrorMsg({
          type: "failed",
          error: "Failed to upload trailer video",
        });
        setIsLoading(false);
        setShowTrailerModal((prev) => false);
      }
    }
  };

  const onCaptureFrame = () => {
    const video = videoRefTrailer.current;
    if (video) {
      setVideoDuration(videoRefTrailer.current.duration);
      // Set up event listener for when video metadata is loaded
      video.onloadedmetadata = () => {
        const durationInSeconds = Math.floor(video.duration);
        setVideoDuration(durationInSeconds);
      };
    }
  };

  const deleteTrailer = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();
    data.append("series_slug", seriesSlug);
    let url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/trailer/`;
    axiosPrivate
      .delete(url, data)
      .then(({ data, status }) => {
        if (status === 200) {
          setErrorMsg({
            error: data ? data?.message : "Series trailer removed successfully",
            type: "success",
          });
        }
        setReload(true);
        setIsLoading(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setReload(true);
        setErrorMsg({
          type: "failed",
          error: "Failed to delete",
        });
      });
  };

  return series ? (
    <div className="series-details">
      {hasAccessToAddVideo && (
        <div
          className="cta-button-create"
          onClick={() => {
            setShowAddVideosModal(true);
            setToastMessage({ type: "", message: "" });
          }}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Add Videos</span>
        </div>
      )}

      <PromptModal
        show={promptAction}
        handleClose={() => setPromptAction(!promptAction)}
        handleDelete={handleDelete}
      />
      <AlertComponent
        type="failed"
        message={errorMessageOnLatestAction}
        setAlertNotification={() => {
          dispatch(changeSeriesStatusFailure(""));
        }}
      />
      <AlertComponent
        setAlertNotification={setMessageResponse}
        type={messageType}
        message={messageResponse}
      />
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />

      <div className="bg-white border mt-2 p-2">
        <div className=" flex gap-x-2 justify-between">
          {/* This container holds series details with tags and notification */}
          <div className="flex w-[80%] gap-x-4">
            <div className="relative">
              <VideoPlayOnSeriesClick
                thumbnail={series?.image}
                slug={series?.slug}
                title={series?.title}
                n_units={series?.n_units || 1}
                thumbnailClassName="rounded-md border"
                thumbnailWrapperClassName="w-[127px] h-[180px] cursor-pointer"
                playIconClassName="text-6xl"
                setMessage={setErrorMsg}
              />

              {series?.image && (
                <FontAwesomeIcon
                  className="absolute cursor-pointer p-1 !w-[25px] !h-[25px] bg-black/70 shadow-md rounded hover:bg-black/90 top-1 right-1"
                  icon={faDownload}
                  color="#ffff"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownloadThumbnail({
                      imageUrl: series?.image,
                      title: series?.title || series?.display_title,
                    });
                  }}
                />
              )}
            </div>

            {/* Series details */}
            <div className="w-[45%]">
              <div className="flex items-center gap-x-2">
                <h6 className="text-black text-[14px] font-bold">
                  #{series?.id}
                </h6>
                <span
                  className="language_chip"
                  style={{
                    border:
                      series?.language === "bn"
                        ? "2px solid green"
                        : "2px solid orange",
                    color: series?.language === "bn" ? "green" : "orange",
                  }}
                >
                  {languageMapping?.[series?.language]}
                </span>
                <Button
                  className="copy-short-link-btn"
                  onClick={() => copyShortLink(seriesSlug)}
                >
                  {isLinkLoading ? "Generating short link" : "Copy Short link"}
                </Button>
              </div>

              <h6 className="text-[14px] font-bold text-black mt-2">
                {series?.display_title}
                <span>
                  {series?.is_premium && (
                    <FontAwesomeIcon
                      className="premium-icon"
                      icon={faCheckCircle}
                      color="#38529A"
                      size="1x"
                    />
                  )}
                </span>{" "}
              </h6>

              {series.category && (
                <span className="series-category">
                  {series?.category?.title}
                </span>
              )}

              <div className="series-creator my-2 cursor-pointer">
                {Object.keys(series?.show || {})?.length > 0 && (
                  <span
                    className="series-category mr-3"
                    onClick={() => history.push(`/shows/${series?.show?.slug}`)}
                  >
                    {series?.show?.title}
                  </span>
                )}
                <Link
                  className="creator-name"
                  to={`/creators/${series?.creator?.id}`}
                >
                  <FontAwesomeIcon icon={faUser} fontSize={12} />{" "}
                  {series?.creator?.name}
                </Link>
              </div>

              <div className="flex-date-status">
                <VideoStatusChip status={series?.status} />

                {series?.is_news && (
                  <Chip className="is_news" label={"Is News"} />
                )}
                <div className="series-created-date">
                  {getDateString(series?.published_on)}
                </div>
              </div>
            </div>

            {/* Tags and notifications */}
            <div>
              {/* TAGS */}
              <div>
                <div className="label text-[14px] text-black">Tags </div>
                <div className="primary">
                  {primaryTags?.map((tag, i) => {
                    return (
                      <span className="tag-tilte" key={i}>
                        {tag?.title}
                      </span>
                    );
                  })}
                </div>
                {primaryTags?.length === 0 && (
                  <Button
                    // style={{ backgroundColor: "darkBlue", color: "lightgray" }}
                    onClick={() => setShowEdit(true)}
                    className="link-notification-cta !text-[12px]"
                  >
                    Add tags
                  </Button>
                )}
              </div>

              {/* Notification */}
              {internalTeamAccess && (
                <div className="seriesbasic-tags mt-2">
                  <div className="label text-[14px] text-black">
                    Notifications{" "}
                  </div>
                  <div
                    className="series-primary-action"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {notificationData?.status !== true ? (
                      <div>
                        <Link
                          state={{ some: "value" }}
                          to={`/notifications/${series?.slug}`}
                          className="link-notification-cta !text-[12px]"
                        >
                          Add Notification
                        </Link>
                      </div>
                    ) : (
                      <Link
                        state={{ some: "value" }}
                        to={`/notifications/${series?.slug}`}
                        className="link-notification-cta !text-[12px]"
                      >
                        Update Notification
                      </Link>
                    )}
                    {notificationData?.status == true && (
                      <IconButton
                        aria-label="delete"
                        style={{ color: "red" }}
                        onClick={() => deleteNotification(notificationData)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Action buttons */}
          <div className="w-fit">
            {/* <div className="center"> {getReviewStatus(series.review_status)}</div> */}
            <div className="edit-delete">
              {isContentChanged ? (
                <div className="changes">
                  <Button
                    className="save"
                    variant="outline-primary"
                    onClick={updateSeriesVideos}
                  >
                    Save
                  </Button>
                  <Button
                    className="cancel"
                    variant="outline-secondary"
                    onClick={() => cancelChanges(true)}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div className="changes">
                  {(seriesCurrentStatus === quickFiltersSeries.UNDER_REVIEW ||
                    seriesCurrentStatus === quickFiltersSeries.SCHEDULED) &&
                    (isAdmin || isSuperAdmin) && (
                      <ButtonGroup
                        className="changes-button-group"
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                      >
                        <Button
                          onClick={() =>
                            setOpenConfirmation({
                              ...openConfirmation,
                              seriesSlug: series?.slug,
                              publishNow: true,
                            })
                          }
                        >
                          Publish Now
                        </Button>
                        <Dropdown className="button-drop-down">
                          <Dropdown.Toggle
                            variant="secondary"
                            className="dropdown-action"
                          >
                            <ArrowDropDownIcon className="rejection-logs w-color mr-3 ml-0" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {!scheduledData?.schedule_on && (
                              <Dropdown.Item
                                onClick={() => setOpenScheduleModal(true)}
                              >
                                Schedule Publish
                              </Dropdown.Item>
                            )}
                            {scheduledData?.schedule_on && (
                              <Dropdown.Item
                                onClick={() => setOpenScheduleModal(true)}
                              >
                                Update Schedule
                              </Dropdown.Item>
                            )}
                            {scheduledData?.schedule_on && (
                              <Dropdown.Item
                                onClick={() => deleteSchedule(seriesSlug)}
                              >
                                Delete Schedule
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </ButtonGroup>
                    )}

                  {hasAccessToEdit && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-action"
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisH}
                          className="rejection-logs mr-3 ml-0"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setShowEdit(true)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            dispatch(
                              fetchRejectionReason(
                                axiosPrivate,
                                series?.slug,
                                setErrorMsg
                              )
                            );
                            setShowRejectionReasonsListForSeriesModal(true);
                          }}
                        >
                          Rejection logs
                        </Dropdown.Item>

                        {internalTeamAccess && (
                          <Dropdown.Item
                            onClick={() => setShowExperiments(true)}
                          >
                            Series CTR Experiments
                          </Dropdown.Item>
                        )}

                        {internalTeamAccess && (
                          <Dropdown.Item
                            onClick={() => setShowAddExperiments(true)}
                          >
                            Add Series CTR Experiments
                          </Dropdown.Item>
                        )}
                        {/* Series Status Modify Options */}

                        {(seriesCurrentStatus === quickFiltersSeries.DRAFT ||
                          seriesCurrentStatus ===
                            quickFiltersSeries.CHANGES_REQUIRED) && (
                          <>
                            {internalTeamAccess && (
                              <Dropdown.Item
                                onClick={() => {
                                  changeSeriesStatusAsync(
                                    series.slug,
                                    "publish"
                                  );
                                }}
                              >
                                Send for Review
                              </Dropdown.Item>
                            )}
                          </>
                        )}

                        {seriesCurrentStatus === quickFiltersSeries.LIVE &&
                          (isAdmin || isSuperAdmin) && (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setOpenConfirmation({
                                    ...openConfirmation,
                                    unPublish: true,
                                    seriesSlug: series.slug,
                                  });
                                }}
                              >
                                Unpublish
                              </Dropdown.Item>
                            </>
                          )}

                        {seriesCurrentStatus ===
                          quickFiltersSeries.CHANGES_REQUIRED ||
                          (seriesCurrentStatus ===
                            quickFiltersSeries.UNDER_REVIEW && (
                            <>
                              {internalTeamAccess && (
                                <Dropdown.Item
                                  onClick={() => {
                                    changeSeriesStatusAsync(
                                      series.slug,
                                      "unpublish"
                                    );
                                  }}
                                >
                                  Move to draft
                                </Dropdown.Item>
                              )}
                            </>
                          ))}

                        {/* {(seriesCurrentStatus ===
                          quickFiltersSeries.UNDER_REVIEW ||
                          (seriesCurrentStatus === quickFiltersSeries.LIVE &&
                            (isAdmin || isSuperAdmin))) && (
                          <>
                            {internalTeamAccess && (
                              <Dropdown.Item
                                onClick={() => {
                                  setShowRejectionReasonModal(true);
                                  setRejectedSeriesSlug(series.slug);
                                  // setSelectedSeries(series);
                                }}
                                style={{ color: "red" }}
                              >
                                Reject
                              </Dropdown.Item>
                            )}
                          </>
                        )} */}

                        {seriesCurrentStatus !== quickFiltersSeries.LIVE &&
                          (isAdmin || isSuperAdmin) && (
                            <Dropdown.Item
                              style={{ color: "red" }}
                              onClick={() =>
                                setOpenConfirmation({
                                  ...openConfirmation,
                                  delete: true,
                                  seriesSlug: series.slug,
                                })
                              }
                            >
                              Delete
                            </Dropdown.Item>
                          )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Video Checks with Approve & Reject */}
        {hasAccessToModerate && (
          <VideoChecks
            series={series}
            setShowEdit={setShowEdit}
            setMessage={setErrorMsg}
            setReload={setReload}
          />
        )}
      </div>

      <div className="videos-drag-list-details-trailer">
        <div className="videos-trailer-block">
          {(trailerData?.original_media_key || trailerData?.media_key) && (
            <div className="img-hover-play">
              <div
                className="play-all-videos play-all-videos-trailer"
                onClick={playTrailerVideos}
              >
                <FontAwesomeIcon icon={faPlayCircle} />
              </div>
              <img
                className="trailer-image"
                src={series?.image}
                alt="No img"
              ></img>
            </div>
          )}
          <div>
            <div>
              Trailer for series <b>#{series?.id} </b>
            </div>
            <div>
              {/* {(trailerData?.original_media_key || trailerData?.media_key) &&
                <Button className="copy-short-link-btn" onClick={() => 
                  copyTrailerUrl(
                    trailerData?.media_url ? trailerData?.media_url : trailerData?.original_media_url
                  )}>
                  Copy trailer link
                </Button>
              } */}
            </div>
          </div>
        </div>
        <div>
          {trailerData?.original_media_key || trailerData?.media_key ? (
            <div className="video-action">
              <IconButton
                color="primary"
                onClick={() => setShowTrailerModal(true)}
              >
                <EditIcon />
              </IconButton>
              <IconButton style={{ color: "red" }} onClick={deleteTrailer}>
                <DeleteIcon />
              </IconButton>
            </div>
          ) : (
            <div>
              {hasAccessToAddVideo && (
                <Button
                  onClick={() => setShowTrailerModal(true)}
                  className="link-notification-cta"
                >
                  Add trailer
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="series-videos">
        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="droppable-1">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {seriesVideos?.map((video, index) => {
                  return (
                    <Draggable
                      key={video?.id}
                      draggableId={`draggable${video?.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="videos videos-drag-list"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging
                              ? "0 0 0.4rem #666"
                              : "none",
                          }}
                        >
                          <div className="videos-drag-list-details">
                            <div className="video-number"> #{index + 1}</div>
                            <br />
                            <VideoDetail
                              setSelectedVideo={setSelectedVideo}
                              video={video}
                              numberOfCharacters={50}
                              imageUrl={video?.image}
                              id={video?.id}
                              title={video?.title}
                              category={video?.category?.name}
                              handleVideoModal={handleVideoModal}
                              updateVideo={() => updateVideo(video)}
                              playIndex={index}
                              videoUrl={
                                video.status.toLowerCase() === "live"
                                  ? video.content.h264_media_url
                                    ? video.content.h264_media_url
                                    : video.content.url
                                  : null
                              }
                              hasQuiz={!!video?.quiz?.id}
                            />
                          </div>
                          {/* <div className="spacer" /> */}
                          {video.activity && video.activity.id && (
                            <div className="activity">
                              <div className="title">
                                {video?.activity?.title &&
                                  video?.activity?.title}
                              </div>
                              <div className="type">
                                {video?.activity?.activity_type?.title}
                              </div>
                            </div>
                          )}
                          {hasAccessToEdit && (
                            <div className="videos-drag-list-action">
                              <div className="video-action">
                                <IconButton
                                  color="primary"
                                  onClick={() => updateVideo(video)}
                                >
                                  <EditIcon />
                                </IconButton>
                                {seriesCurrentStatus !==
                                  quickFiltersSeries.LIVE &&
                                  (isSuperAdmin || isAdmin) && (
                                    <IconButton
                                      style={{ color: "red" }}
                                      onClick={() => removeVideo(video)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                              </div>
                              <div
                                {...provided.dragHandleProps}
                                className="drag-icon"
                              >
                                <DragIndicatorIcon />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="loader" ref={lastVideoElementRef}>
            {hasMoreVideos && <Loader />}
          </div>
        </DragDropContext>

        {selectedVideo && (
          <Modal show={showUpdate} centered>
            <Modal.Header closeButton onHide={() => setShowUpdate(false)}>
              <div className="modal-video-title center">
                Update Video #{selectedVideo?.id}
              </div>
            </Modal.Header>
            {selectedVideo.status.toLowerCase() === "live" ? (
              <UpdateVideo
                videoSlug={selectedVideo?.slug}
                setShowUpdate={setShowUpdate}
                reload={() => setReload(true)}
              />
            ) : (
              <div className="center">Source Not Found</div>
            )}
          </Modal>
        )}

        {/* Video Modal */}
        <Modal
          style={{ width: "120vw" }}
          className="video-modal"
          show={showVideo}
          centered
        >
          <Modal.Header closeButton onHide={handleVideoModal}>
            <div className="modal-video-title">
              {videoTitle?.length > 60
                ? videoTitle?.substr(0, 60) + "..."
                : videoTitle}
            </div>
          </Modal.Header>
          <div className="modal-video-player" style={{ height: "100%" }}>
            <div className="icon">
              {playIndex > 0 && (
                <div className="prev-icon" onClick={playVideoPrev}>
                  <FontAwesomeIcon
                    size="2x"
                    className="icon"
                    icon={faChevronCircleLeft}
                  />
                </div>
              )}
              {(playIndex < seriesVideos?.length - 1 || hasMoreVideos) && (
                <div className="next-icon" onClick={playVideoNext}>
                  <FontAwesomeIcon
                    size="2x"
                    className="icon"
                    icon={faChevronCircleRight}
                  />
                </div>
              )}
            </div>
            {videoSource ? (
              <VideoPlayerCustom
                showNextPrev={true}
                controlProps={{
                  playIndex: playIndex,
                  seriesVideos: seriesVideos,
                  hasMoreVideos: hasMoreVideos,
                  playVideoPrev: playVideoPrev,
                  playVideoNext: playVideoNext,
                }}
                src={videoSource}
                controls={true}
                muted={false}
                autoplay={true}
                onEnded={onVideoEnd}
                onTimeUpdate={() => {}}
                videoRef={videoRef}
                currentTime={0}
              />
            ) : (
              <div className="message">Source Not Found</div>
            )}
          </div>
        </Modal>
      </div>

      {series && (
        <Modal show={showAddVideosModal} centered>
          <Modal.Header closeButton onHide={() => setShowAddVideosModal(false)}>
            <div className="modal-video-title">Add Videos</div>
          </Modal.Header>
          <Modal.Body>
            <AddSeriesVideos
              creatorId={series?.creator?.id}
              categoryId={series?.category?.id}
              seriesSlug={seriesSlug}
              seriesVideos={seriesVideos}
              addVideoToSeries={addSeriesVideo}
            />
          </Modal.Body>
        </Modal>
      )}

      {series && (
        <Modal show={showExperiments} centered>
          <Modal.Header closeButton onHide={() => setShowExperiments(false)}>
            <div className="modal-experiments-title">Experiments</div>
          </Modal.Header>
          <Modal.Body>
            <SeriesExperiments
              setErrorMsg={setErrorMsg}
              series={series}
              setSeries={setSeries}
              close={() => setShowExperiments(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      {series && (
        <Modal show={showAddExperiments} centered>
          <Modal.Header closeButton onHide={() => setShowAddExperiments(false)}>
            <div className="modal-experiments-title">Experiments</div>
          </Modal.Header>
          <Modal.Body>
            <AddExperiment
              series={series}
              close={() => setShowAddExperiments(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      {series && (
        <CustomModal
          title="Edit Video"
          show={showEdit}
          isButtonsRequired={false}
          className="lg:!w-[33%]"
          onHide={() => setShowEdit(false)}
        >
          <EditSeries
            primaryTags={primaryTags}
            secondaryTags={secondaryTags}
            series={series}
            setSeries={setSeries}
            setPageNumber={setPageNumber}
            setReload={() => {
              // setPageNumber(1);
              setReload(true);
              setShowEdit(false);
            }}
          />
        </CustomModal>
      )}

      {series && (
        <>
          {/* SHOW REJECTION REASON MODAL */}
          <Modal show={showRejectionReasonModal} centered>
            <Modal.Header closeButton onHide={toggleRejection}>
              <div className="modal-video-title">Enter Rejection Reason</div>
            </Modal.Header>
            <Modal.Body>
              <Form className="">
                <Form.Group className="">
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Enter the reason for rejection..."
                    style={{ width: 450 }}
                    name="rejection_reason"
                    value={rejectionReasonField}
                    onChange={onRejectionFieldChange}
                  />
                </Form.Group>
                <Form.Group className=" d-flex justify-content-center">
                  <Button
                    variant="contained"
                    color="success"
                    className=""
                    onClick={rejectReasonSubmit}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>

          {/* Rejection data modal */}
          <Modal show={showRejectionReasonsListForSeriesModal} centered>
            <Modal.Header closeButton onHide={toggleRejectionSeriesList}>
              <div className="modal-video-title">List of rejection reasons</div>
            </Modal.Header>
            <Modal.Body>{renderRejectedReasonData()}</Modal.Body>
          </Modal>
        </>
      )}

      {series && (
        <>
          {/* Show trailer upload form*/}
          <Modal show={showTrailerModal} centered>
            <Modal.Header
              closeButton
              onHide={() => {
                setShowTrailerModal((prev) => !prev);
                setTrailerData((prev) => {
                  return {
                    ...prev,
                    file: "",
                  };
                });
                setIsLoading(false);
              }}
            >
              <div className="modal-video-title">
                {trailerData?.original_media_key || trailerData?.media_key
                  ? "Edit"
                  : "Update"}{" "}
                trailer video
              </div>
            </Modal.Header>
            <Modal.Body>
              <Form className="">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Select Video to Upload</Form.Label>
                  <Form.Control
                    style={{ height: 30 }}
                    accept="video/mp4"
                    type="file"
                    required
                    name="video"
                    onChange={(e) => {
                      setIsFileLoading(true);
                      setTrailerData((prev) => {
                        return {
                          ...prev,
                          file: e.target.files[0],
                        };
                      });
                      setTimeout(() => onCaptureFrame(), 1000);
                      setIsFileLoading(false);
                    }}
                  />
                </Form.Group>
                <div className="trailer-player-preview">
                  {trailerData?.file && (
                    <video width="240" controls ref={videoRefTrailer}>
                      <source src={URL.createObjectURL(trailerData?.file)} />
                    </video>
                  )}
                </div>
                <Form.Group className="d-flex justify-content-center">
                  <Button
                    className="cta-update-series"
                    variant="primary"
                    onClick={handleTrailerSubmit}
                  >
                    {isLoading ? "Uploading..." : "Submit"}
                  </Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>

          {/* Rejection data modal */}
          <Modal show={showRejectionReasonsListForSeriesModal} centered>
            <Modal.Header closeButton onHide={toggleRejectionSeriesList}>
              <div className="modal-video-title">List of rejection reasons</div>
            </Modal.Header>
            <Modal.Body>{renderRejectedReasonData()}</Modal.Body>
          </Modal>
        </>
      )}

      <Modal
        // className="video-mod schedule-modal"
        show={openScheduleModal}
        centered
      >
        <Modal.Header
          closeButton
          onHide={() => {
            setOpenScheduleModal(!openScheduleModal);
            setErrorForScheduling([]);
            // setScheduledData({});
          }}
        >
          <div className="modal-schedule-title">
            <div className="modal-schedule-header">Schedule Publish</div>
            <div className="modal-schedule-subtext">India Standard Time</div>
          </div>
        </Modal.Header>
        {errorForScheduling?.length > 0 ? (
          errorForScheduling?.map((dat, i) => (
            <ul className="modal-schedule-content-ul" key={i}>
              <li className="text-[15px]">
                <span className="text-red-500">⚠️ Issue </span>
                {i + 1}. <strong>{dat.series_slug}</strong> –{" "}
                {dat?.error_message}
              </li>
            </ul>
          ))
        ) : (
          <div className="modal-schedule-content">
            <ul className="modal-schedule-content-ul">
              {schedularTimeList(os)?.map((dateInfo, i) => {
                return (
                  <li
                    key={i}
                    className="modal-schedule-content-li"
                    onClick={() =>
                      setUpScheduleDate(
                        dateInfo,
                        "",
                        scheduledData?.schedule_on
                      )
                    }
                  >
                    <div className="modal-date-title">{dateInfo?.title}</div>
                    <div>
                      <span className="modal-date-date">
                        {getDateTimeFormat(dateInfo?.value)}
                      </span>
                      <span className="modal-date-time">{dateInfo.time}</span>
                    </div>
                  </li>
                );
              })}
              {scheduledData?.schedule_on && (
                <li className={`modal-schedule-content-li selected-date`}>
                  <div className="modal-date-title">Scheduled Date</div>
                  <div>
                    <span className="modal-date-date">
                      {handleFormatDate({
                        dateInString: scheduledData?.schedule_on,
                      })}
                    </span>
                  </div>
                </li>
              )}
            </ul>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Select time and Date"
                  disablePast
                  ampm={false}
                  onAccept={(newValue) =>
                    newValue &&
                    setUpScheduleDate(
                      newValue?.$d,
                      "custom",
                      scheduledData?.schedule_on
                    )
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
      </Modal>

      <Modal
        style={{ width: "120vw" }}
        className="video-modal"
        show={playTrailer}
        centered
      >
        <Modal.Header
          closeButton
          onHide={() => setPlayTrailer((prev) => !prev)}
        >
          <div className="modal-video-title">
            <div>
              Trailer for series - <b>#{series?.id} </b>
            </div>
          </div>
        </Modal.Header>
        <div className="modal-video-player" style={{ height: "100%" }}>
          {trailerData?.original_media_key || trailerData?.media_key ? (
            <VideoPlayerCustom
              showNextPrev={false}
              src={
                trailerData?.media_url
                  ? trailerData?.media_url
                  : trailerData?.original_media_url
              }
              controls={true}
              muted={false}
              autoplay={true}
              onEnded={onVideoEnd}
              onTimeUpdate={() => {}}
              videoRef={videoRef}
              currentTime={0}
            />
          ) : (
            <div className="message">Source Not Found</div>
          )}
        </div>
      </Modal>

      {/* Actions confirmation */}
      {openConfirmationIf && (
        <CustomModal
          show={openConfirmationIf}
          isLoading={openConfirmation?.isLoading}
          title={
            openConfirmation?.unPublish ? "Unpublish Video" : "Delete Video"
          }
          onHide={() =>
            setOpenConfirmation(videoDetailConfirmationDefaultState)
          }
          handleConfirmation={() => {
            if (openConfirmation?.unPublish) {
              changeSeriesStatusAsync(
                openConfirmation?.seriesSlug,
                "unpublish"
              );
            } else if (openConfirmation?.delete) {
              deleteSeries();
            } else if (openConfirmation?.publishNow) {
              changeSeriesStatusAsync(series.slug, "publish_now");
            }
          }}
        >
          <div>
            Are you sure you want to{" "}
            {openConfirmation?.unPublish ? (
              <span className="text-red-500 font-semibold">Unpublish</span>
            ) : openConfirmation?.publishNow ? (
              <spa className="text-green-500 font-semibold">Publish</spa>
            ) : (
              <span className="text-red-500 font-semibold">Delete</span>
            )}{" "}
            this series?{" "}
          </div>
        </CustomModal>
      )}
    </div>
  ) : (
    <div className="center-loader-screen">
      <Loader />
    </div>
  );
}

export default withRouter(SeriesDetails);
